define("discourse/plugins/discourse-data-explorer/discourse/components/data-explorer-bar-chart", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/lib/load-script", "discourse-common/utils/decorators", "discourse/plugins/discourse-data-explorer/discourse/lib/themeColor", "@ember/template-factory"], function (_exports, _component, _component2, _object, _loadScript, _decorators, _themeColor, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <canvas
    {{did-insert this.initChart}}
    {{on "change" this.updateChartData}}
  ></canvas>
  */
  {
    "id": "rMRjbU49",
    "block": "[[[11,\"canvas\"],[4,[38,0],[[30,0,[\"initChart\"]]],null],[4,[38,1],[\"change\",[30,0,[\"updateChartData\"]]],null],[12],[13]],[],false,[\"did-insert\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/data-explorer-bar-chart.hbs",
    "isStrictMode": false
  });
  let DataExplorerBarChart = _exports.default = (_class = class DataExplorerBarChart extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "chart", void 0);
      _defineProperty(this, "barsColor", (0, _themeColor.default)("--tertiary"));
      _defineProperty(this, "barsHoverColor", (0, _themeColor.default)("--tertiary-high"));
      _defineProperty(this, "gridColor", (0, _themeColor.default)("--primary-low"));
      _defineProperty(this, "labelsColor", (0, _themeColor.default)("--primary-medium"));
    }
    get config() {
      const data = this.data;
      const options = this.options;
      return {
        type: "bar",
        data,
        options
      };
    }
    get data() {
      const labels = this.args.labels;
      return {
        labels,
        datasets: [{
          label: this.args.datasetName,
          data: this.args.values,
          backgroundColor: this.barsColor,
          hoverBackgroundColor: this.barsHoverColor
        }]
      };
    }
    get options() {
      return {
        scales: {
          legend: {
            labels: {
              fontColor: this.labelsColor
            }
          },
          xAxes: [{
            gridLines: {
              color: this.gridColor,
              zeroLineColor: this.gridColor
            },
            ticks: {
              fontColor: this.labelsColor
            }
          }],
          yAxes: [{
            gridLines: {
              color: this.gridColor,
              zeroLineColor: this.gridColor
            },
            ticks: {
              beginAtZero: true,
              fontColor: this.labelsColor
            }
          }]
        }
      };
    }
    async initChart(canvas) {
      await (0, _loadScript.default)("/javascripts/Chart.min.js");
      const context = canvas.getContext("2d");
      // eslint-disable-next-line
      this.chart = new Chart(context, this.config);
    }
    updateChartData() {
      this.chart.data = this.data;
      this.chart.update();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.chart.destroy();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "initChart", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChartData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateChartData"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataExplorerBarChart);
});