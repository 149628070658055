define("discourse/plugins/discourse-data-explorer/discourse/controllers/group-reports-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/components/modal/bookmark", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/bookmark-form-data", "discourse/models/bookmark", "discourse-common/utils/decorators"], function (_exports, _tracking, _controller, _object, _service, _bookmark, _ajax, _ajaxError, _bookmarkFormData, _bookmark2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GroupReportsShowController = _exports.default = (_class = class GroupReportsShowController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "showResults", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
      _initializerDefineProperty(this, "results", _descriptor5, this);
      _initializerDefineProperty(this, "queryGroupBookmark", _descriptor6, this);
      _defineProperty(this, "explain", false);
    }
    get hasParams() {
      return this.model.param_info.length > 0;
    }
    get bookmarkLabel() {
      return this.queryGroupBookmark ? "bookmarked.edit_bookmark" : "bookmarked.title";
    }
    get bookmarkIcon() {
      if (this.queryGroupBookmark && this.queryGroupBookmark.reminder_at) {
        return _bookmark2.WITH_REMINDER_ICON;
      }
      return _bookmark2.NO_REMINDER_ICON;
    }
    get bookmarkClassName() {
      return this.queryGroupBookmark ? ["query-group-bookmark", "bookmarked"].join(" ") : "query-group-bookmark";
    }
    async run() {
      this.loading = true;
      this.showResults = false;
      try {
        const response = await (0, _ajax.ajax)(`/g/${this.get("group.name")}/reports/${this.model.id}/run`, {
          type: "POST",
          data: {
            params: JSON.stringify(this.model.params),
            explain: this.explain
          }
        });
        this.results = response;
        if (!response.success) {
          return;
        }
        this.showResults = true;
      } catch (error) {
        if (error.jqXHR?.status === 422 && error.jqXHR.responseJSON) {
          this.results = error.jqXHR.responseJSON;
        } else {
          (0, _ajaxError.popupAjaxError)(error);
        }
      } finally {
        this.loading = false;
      }
    }
    toggleBookmark() {
      const modalBookmark = this.queryGroupBookmark || this.store.createRecord("bookmark", {
        bookmarkable_type: "DiscourseDataExplorer::QueryGroup",
        bookmarkable_id: this.queryGroup.id,
        user_id: this.currentUser.id
      });
      return this.modal.show(_bookmark.default, {
        model: {
          bookmark: new _bookmarkFormData.BookmarkFormData(modalBookmark),
          afterSave: bookmarkFormData => {
            const bookmark = this.store.createRecord("bookmark", bookmarkFormData.saveData);
            this.queryGroupBookmark = bookmark;
            this.appEvents.trigger("bookmarks:changed", bookmarkFormData.saveData, bookmark.attachedTo());
          },
          afterDelete: () => {
            this.queryGroupBookmark = null;
          }
        }
      });
    }

    // This is necessary with glimmer's one way data stream to get the child's
    // changes of 'params' to bubble up.
    updateParams(identifier, value) {
      this.set(`model.params.${identifier}`, value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.model.results;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "queryGroupBookmark", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.queryGroup?.bookmark;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "run", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "run"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBookmark", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBookmark"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateParams"), _class.prototype)), _class);
});